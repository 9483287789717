<template>
  <div v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      default: '',
    },
    name: {
      required: true,
    },
    selected: {
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    href() {
      return `#${this.name.toLowerCase()
        .replace(/ /g, '-')}`;
    },
    hasIcon() {
      return this.icon !== '';
    },
  },
  mounted() {
    this.isActive = this.selected;
  },
};
</script>
